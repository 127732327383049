import { ParsedLocation, redirect } from '@tanstack/react-router';

export type RootContext = {
  auth: {
    isAuthenticated: boolean;
  };
};

export const beforeLoad = ({ context, location }: { context: RootContext; location: ParsedLocation }) => {
  if (!context.auth?.isAuthenticated) {
    throw redirect({
      to: '/login',
      search: {
        redirect: location.href,
      },
    });
  }
};
