import { Avatar, AvatarFallback } from '@/components/ui/avatar.tsx';
import { VITE_LANGSMITH_URL } from '@/config.ts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import { Messages } from '@/feature/chat/chat.types.ts';
import { normalizeTransferTo } from '@/feature/chat/chat.utils.ts';
import { toFormattedTime } from '@/lib/date-utils';

export const ChatMessages = ({ messages }: { messages: Messages }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Messages</CardTitle>
        <CardDescription />
      </CardHeader>
      <CardContent className="grid gap-8">
        {messages.map((message, index) => {
          const { author, text, type, transferTo, transferToFirstName, transferToLastName, transferToDepartment } =
            message;
          const isAiMessage = author === 'assistant';
          const userName = author === 'assistant' ? 'Assistant' : 'User';
          const toNormalizedTransferTo = normalizeTransferTo(transferTo);

          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="relative flex items-center gap-4" key={`${text}-${index}`}>
              <Avatar className="hidden size-9 sm:flex">
                <AvatarFallback className={isAiMessage ? 'bg-slate-300' : ''}>{isAiMessage ? 'A' : 'U'}</AvatarFallback>
              </Avatar>
              <p className="absolute right-0 top-0 text-xs font-medium" title={message.createdAt}>
                {toFormattedTime(message.createdAt)}
              </p>
              <div className="grid gap-1">
                <p className="text-sm font-medium leading-none">{userName}</p>
                <p className="text-sm text-muted-foreground">{text}</p>

                {isAiMessage && (
                  <>
                    <p className="text-sm text-muted-foreground">
                      <span className="font-semibold">Type:</span> {type}
                    </p>
                    {toNormalizedTransferTo && (
                      <p className="text-sm text-muted-foreground">
                        <span className="font-semibold">
                          Transfer to {transferToFirstName} {transferToLastName} {transferToDepartment || ''}:{' '}
                        </span>
                        {toNormalizedTransferTo}
                      </p>
                    )}
                    <a
                      href={`${VITE_LANGSMITH_URL}?peek=${message.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-sm text-muted-foreground"
                    >
                      Debug link
                    </a>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
};
