import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Chevron, DayPicker, type DayPickerProps } from 'react-day-picker';

export type CalendarProps = DayPickerProps;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-row gap relative',
        month_caption: 'flex justify-center h-7 mx-10 relative items-center',
        weekdays: 'flex flex-row',
        weekday: 'text-muted-foreground w-8 font-normal text-[0.8rem]',
        month: 'gap-y-4 [&:not(:last-child)]:mr-4 overflow-x-hidden w-full',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium truncate',
        button_next: cn(
          buttonVariants({
            variant: 'outline',
            className: 'absolute right-0 h-7 w-7 bg-transparent p-0 opacity-80 hover:opacity-100',
          }),
        ),
        button_previous: cn(
          buttonVariants({
            variant: 'outline',
            className: 'absolute left-0 h-7 w-7 bg-transparent p-0 opacity-80 hover:opacity-100',
          }),
        ),
        nav: 'flex items-start',
        month_grid: 'mt-4',
        week: 'flex w-full mt-2',
        day: 'p-0 size-8 text-sm flex-1 flex items-center justify-center has-[button]:hover:!bg-accent rounded-md has-[button]:hover:aria-selected:!bg-primary has-[button]:hover:text-accent-foreground has-[button]:hover:aria-selected:text-primary-foreground',
        day_button: cn(
          buttonVariants({ variant: 'ghost' }),
          'size-8 p-0 font-normal transition-none hover:bg-transparent hover:text-inherit aria-selected:opacity-100',
        ),
        range_start: 'rounded-none rounded-bl-md rounded-tl-md',
        range_end: 'rounded-none rounded-br-md rounded-tr-md',
        range_middle: 'rounded-none',
        selected:
          'aria-selected:bg-accent hover:aria-selected:!bg-accent aria-selected:text-accent-foreground hover:aria-selected:text-accent-foreground',
        today: 'bg-accent text-accent-foreground',
        outside:
          'text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        disabled: 'text-muted-foreground opacity-50',
        hidden: 'invisible',
        ...classNames,
      }}
      components={{
        // eslint-disable-next-line react/no-unstable-nested-components
        Chevron: ({ orientation, ...rest }) => {
          switch (orientation) {
            case 'left':
              return <ChevronLeftIcon className="size-4" />;
            case 'right':
              return <ChevronRightIcon className="size-4" />;
            default:
              return <Chevron orientation={orientation} {...rest} />;
          }
        },
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
