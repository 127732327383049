export const toFormattedDate = (date: string) => {
  const normalizedDate = new Date(date);
  const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' });

  return formatter.format(normalizedDate);
};

export const toFormattedTime = (date: string) => {
  const normalizedDate = new Date(date);
  const formatter = new Intl.DateTimeFormat('en-US', { timeStyle: 'medium' });

  return formatter.format(normalizedDate);
};
