import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui/button';
import { LogOut } from 'lucide-react';

const Logout = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    void logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Button variant="ghost" size="icon" onClick={handleLogout}>
      <LogOut className="size-4" />
    </Button>
  );
};

export default Logout;
