import { FC, JSX, PropsWithChildren } from 'react';

type DivProps = JSX.IntrinsicElements['div'];

type DataContainerProps = DivProps & {
  title: string;
};

export const DataContainer: FC<DataContainerProps> = ({ children, title, ...props }) => (
  <div {...props}>
    <h3 className="mb-2 mt-8 scroll-m-20 text-2xl font-semibold tracking-tight">{title}</h3>
    {children}
  </div>
);

export const createStateContainer = (title: string) => {
  const Component: FC<DivProps> & { Loading: FC<DivProps>; NoData: FC<DivProps>; Error: FC<DivProps> } = (props) => {
    return <DataContainer title={title} {...props} />;
  };

  Component.Loading = ({ children = 'Loading...', ...props }: DivProps) => {
    return <Component {...props}>{children}</Component>;
  };

  Component.NoData = ({ children = 'No data', ...props }: DivProps) => {
    return <Component {...props}>{children}</Component>;
  };

  Component.Error = ({ children = 'Something went wrong', ...props }: DivProps) => {
    return <Component {...props}>{children}</Component>;
  };

  return Component;
};
