// Import the generated route tree
import { routeTree } from './routeTree.gen';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { useAuth0 } from '@auth0/auth0-react';

const router = createRouter({
  routeTree,
  context: {
    auth: { isAuthenticated: false },
  },
});

// Register things for typesafety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  // https://github.com/TanStack/router/issues/1604#issuecomment-2118648159
  return isLoading ? (
    <div className="p-2">Loading...</div>
  ) : (
    <RouterProvider router={router} context={{ auth: { isAuthenticated } }} />
  );
};
