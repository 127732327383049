import { FC } from 'react';
import { useActiveCalls } from './use-active-calls';
import { Link } from '@tanstack/react-router';
import { onlyTime } from '@/utils/date.utils';
import { createStateContainer } from '@/components/data-container';

const Container = createStateContainer('Active calls');

export const ActiveCalls: FC = () => {
  const { data, error } = useActiveCalls();

  if (error) {
    return <Container.Error>{error}</Container.Error>;
  }

  if (!data) {
    return <Container.Loading />;
  }

  if (!data.length) {
    return <Container.NoData>No active calls</Container.NoData>;
  }

  return (
    <Container>
      <div>
        <div className="mb-2 flex justify-between align-middle">
          <div className="text-lg font-semibold">Total: {data.length}</div>
        </div>
        <ul className="list-disc">
          {data.map((call) => (
            <li className="mb-1 ml-5 last:mb-0" key={call.chatId}>
              <Link className="flex justify-between no-underline" to="/chats/$chatId" params={{ chatId: call.chatId }}>
                <div>
                  {call.from}
                  {call.fromName ? ` (${call.fromName})` : ''}
                </div>
                <div>{onlyTime(call.createdAt)}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};
