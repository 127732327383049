import { type FC } from 'react';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { DateRange } from 'react-day-picker';

type RangeDatePickerProps = {
  id?: string;
  selected?: DateRange;
  onSelect?: (selected?: DateRange) => void;
  placeholder?: string;
  className?: string;
};

// if min < 0, then we will have from and to dates on first click
// https://github.com/gpbl/react-day-picker/blob/6b0abf9710d7d822de366924ffe8591d2d6fc285/src/utils/addToRange.ts#L30
export const RangeDatePicker: FC<RangeDatePickerProps> = ({
  id,
  selected,
  onSelect,
  className,
  placeholder = 'Pick a date range',
}) => {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id={id}
            variant={'outline'}
            className={cn(
              'gap-2 min-w-[250px] justify-start text-left font-normal',
              !selected && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="size-4" />

            <span>
              {selected?.from ? (
                selected.to ? (
                  <>
                    {format(selected.from, 'LLL dd, y')} - {format(selected.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(selected.from, 'LLL dd, y')
                )
              ) : (
                placeholder
              )}
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar autoFocus mode="range" selected={selected} onSelect={onSelect} min={1} numberOfMonths={2} />
        </PopoverContent>
      </Popover>
    </div>
  );
};
