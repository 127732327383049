import { trpc } from '@/trpc.ts';
import { ColumnDef } from '@tanstack/react-table';
import { DataTable } from '@/components/ui/data-table';
import { AppRouter } from '@receptionist/api';
import { inferRouterOutputs } from '@trpc/server';
import { Link } from '@tanstack/react-router';
import { toFormattedDate } from '@/lib/date-utils.ts';
import { ChatRecording } from '../chat-recording/chat-recording';
import { useCallFilters } from '../call-filters/call-filters';
import { createStateContainer } from '@/components/data-container';

type RouterOutput = inferRouterOutputs<AppRouter>;

type ChatType = RouterOutput['getChats']['data'][number];

const columns: ColumnDef<ChatType>[] = [
  {
    accessorKey: 'id',
    header: 'Chat log',
    size: 100,
    cell: ({ row }) => (
      <Link to="/chats/$chatId" params={{ chatId: row.getValue('id') as string }}>
        Chat log
      </Link>
    ),
  },
  {
    accessorKey: 'createdAt',
    header: 'Date Time',
    size: 150,
    cell: ({ row }) => {
      const createdAt = row.getValue('createdAt') as string;
      const formattedDate = toFormattedDate(createdAt);
      const [date, time] = formattedDate.split(',');

      return (
        <div>
          <div>{date}</div>
          <div>{time}</div>
        </div>
      );
    },
  },
  {
    accessorKey: 'fromName',
    header: 'Caller Name',
    size: 150,
    cell: ({ row }) => <div>{row.getValue('fromName')}</div>,
  },
  {
    accessorKey: 'from',
    header: 'From',
    size: 120,
    cell: ({ row }) => <div>{row.getValue('from')}</div>,
  },
  {
    accessorKey: 'summary',
    header: 'Summary',
    size: 700,
    cell: ({ row }) => <div>{row.getValue('summary')}</div>,
  },
  {
    header: 'Recording',
    size: 320,
    cell: ({ row }) => {
      const id = row.getValue('id') as string;

      return <ChatRecording id={id} />;
    },
  },
];

const Container = createStateContainer('Call logs');

export const CallLogs = () => {
  const { filters } = useCallFilters();
  const { data, isLoading, error, isFetching, fetchNextPage, hasNextPage } = trpc.getChats.useInfiniteQuery(
    { limit: 50, ...filters },
    { getNextPageParam: (lastPage) => lastPage.nextCursor },
  );
  const flatData = data?.pages.flatMap((page) => page.data) ?? [];
  const totalCount = data?.pages[0]?.total ?? 0;

  if (error) {
    return <Container.Error />;
  }

  if (isLoading) {
    return <Container.Loading />;
  }

  if (!flatData) {
    return <Container.NoData />;
  }

  return (
    <Container>
      <DataTable
        data={flatData}
        columns={columns}
        isFetchingNextPage={isFetching}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        totalCount={totalCount}
      />
    </Container>
  );
};
