import { trpc } from '@/trpc.ts';
import { CallStatisticsItem } from '@/feature/call-statistics/call-statistics-item';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { normalizeStatistics } from '@/feature/call-statistics/call-statistics.utils.ts';
import { normalizeTransferTo } from '@/feature/chat/chat.utils.ts';
import { useCallFilters } from '../call-filters/call-filters';
import { createStateContainer } from '@/components/data-container';

const Container = createStateContainer('Call statistics');

export const CallStatistics = () => {
  const { filters } = useCallFilters();
  const { data: statisticsData, isLoading, error } = trpc.getStatistics.useQuery(filters);
  const {
    totalCals,
    transferredTo,
    totalTransferredTo,
    answeredByReceptionCount,
    transferredToLiveAgentCount,
    loadUpdatesCount,
  } = normalizeStatistics(statisticsData);

  if (error) {
    return <Container.Error />;
  }

  if (isLoading) {
    return <Container.Loading />;
  }

  if (!statisticsData) {
    return <Container.NoData />;
  }

  return (
    <Container>
      <div className="mt-4 grid gap-3 lg:grid-cols-2">
        <div>
          <div className="grid gap-3 lg:grid-cols-2">
            <CallStatisticsItem title="Total calls answered" count={totalCals} />

            <CallStatisticsItem
              title="Answered by receptionist"
              count={answeredByReceptionCount}
              description="Answered and finished (conversation end)"
            />

            <CallStatisticsItem title="Tansferred to live agent" count={transferredToLiveAgentCount} />

            <CallStatisticsItem
              title="Load updates"
              count={loadUpdatesCount}
              description="User asked for load updates"
            />
          </div>
        </div>

        <div>
          <Card>
            <CardHeader className="space-y-0 pb-0">
              <CardTitle className="text-sm font-medium">
                Total calls transferred to employees/departments: {totalTransferredTo}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="mt-4 grid max-h-[310px] gap-3 overflow-y-auto pb-2 lg:grid-cols-3">
                {transferredTo?.map(
                  ({ count, transferTo, transferToFirstName, transferToLastName, transferToDepartment }) => {
                    const title = `${transferToFirstName} ${transferToLastName}${transferToDepartment ? `, ${transferToDepartment}` : ''}`;
                    const toNormalizedTransferTo = normalizeTransferTo(transferTo);

                    return (
                      <CallStatisticsItem
                        key={transferTo}
                        title={title}
                        count={count}
                        description={toNormalizedTransferTo}
                      />
                    );
                  },
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </Container>
  );
};
